import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useRecoilState, useRecoilValue, useResetRecoilState} from "recoil";
import {loginState} from "./atoms";
import Input from "./Input";
import {notificationAlert} from "../../util/alert";
import {loaderState} from "../common/loader/atoms";
import Loader from "../common/loader/Loader";
import {generateRandomCode} from "../../util/common";
import {login} from "../../repositories/AuthRepository";


const Login: React.FC = () => {
    const resetLoginState = useResetRecoilState(loginState);
    const [loader, setLoader] = useRecoilState(loaderState);
    const state = useRecoilValue(loginState);
    const navigate = useNavigate();

    useEffect(() => {
        resetLoginState();
    }, [resetLoginState])

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();
        try {
            setLoader(old => ({...old, login: true}))

            const result = await login(state.id, state.password);

            setLoader(old => ({...old, login: false}))
            if (result === 'ok') {
                const redirect = localStorage.getItem('redirect');
                if(redirect) {
                    if(redirect === '/list') {
                        navigate('/list');
                    }else if(redirect === '/'){
                        navigate('/')
                    }else {
                        navigate('/')
                    }
                }else {
                    navigate('/')
                }
                localStorage.removeItem('redirect');
            } else {
                notificationAlert('notification', "Please check your ID or password.")
            }

            // if (state.id === process.env.REACT_APP_ID && state.password === process.env.REACT_APP_PWD) {
            //     setLoader(old => ({...old, login: false}))
            //     // localStorage.setItem('fmEE', generateRandomCode(16));
            //     navigate('/list');
            // } else {
            //     setLoader(old => ({...old, login: false}))
            //     notificationAlert('알림', "아이디 혹은 비밀번호를 확인해주세요.")
            // }


        } catch (err: any) {
            if (err) {
                setLoader(old => ({...old, login: false}))
                if (err.message) {
                    notificationAlert('error', err.message)
                } else {
                    notificationAlert('error', 'There was a problem logging in.')
                }
            }
        }

    }


    return (
        <div className={'login_main'}>
            <div className='login_box'>
                <h3 id={'box_title'}>Login</h3>
                <h4 id={'box_sub_title'}>Please register again.</h4>
                <form onSubmit={handleSubmit}>
                    <div className='form_group'>
                        <Input type="text" name="id" placeholder="ID"/>
                    </div>
                    <div className='form_group'>
                        <Input type="password" name="password" placeholder="PASSWORD"/>
                    </div>
                    <div style={{textAlign: 'right', padding: '0'}}>
                        <Link style={{color: '#949494', textDecoration: 'none', fontSize: '1.1rem'}}
                              to={'/register'}>register</Link>
                    </div>
                    <div className={'form_btn_wrapper'}>
                        <button type={"submit"} id={'login_submit_btn'}>
                            {loader.login ? <Loader height={25}/> : 'Login'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login;
