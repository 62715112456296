import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    onSnapshot,
    query,
    Unsubscribe,
    updateDoc,
    Timestamp,
    getDoc,
    orderBy,
    where, getDocs
} from "firebase/firestore";
import {firestore} from "../firebase";
import {FirebaseFormData} from "../model/FirebaseFormData";
import {UpdateFormRequest} from "../model/UpdateFormRequest";
import {FirebaseUserData} from "../model/FirebaseUserData";
import {isIterableArray} from "../util/common";
import {getUser} from "./AuthRepository";

const FORM_COLLECTION_NAME = "forms"

export const checkExistForm = async (): Promise<boolean> => {
    const userQuery = query(collection(firestore, FORM_COLLECTION_NAME), where('userId', '==', localStorage.getItem('fmEE') as string));
    const userSnap = await getDocs(userQuery);
    return !userSnap.empty;
}

export const createForm = async (
    request: FirebaseFormData
): Promise<boolean> => {
    request.userId = localStorage.getItem('fmEE') as string
    request.createAt = Timestamp.now()
    await addDoc(collection(firestore, FORM_COLLECTION_NAME), request);
    return true;
}

export const deleteForm = async (
    formId: string
): Promise<boolean> => {
    await deleteDoc(doc(firestore, FORM_COLLECTION_NAME, formId));
    return true;
}

export const updateForm = async (
    request: UpdateFormRequest
): Promise<boolean> => {
    await updateDoc(doc(firestore, FORM_COLLECTION_NAME, request.id), request);
    return true;
}

export const getDetailForm = async (
    id: string
): Promise<FirebaseFormData> => {
    const user = await getUser();

    const docRef = doc(firestore, FORM_COLLECTION_NAME, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        if(user.role !== 'ADMIN' && user.documentId !== docSnap.data().userId) {
            throw Error("You do not have permission.");
        }

        return {id: docSnap.id, ...docSnap.data()} as FirebaseFormData
    } else {
        throw Error("This page does not exist.");
    }
}

export const getFormList = (
    user: FirebaseUserData,
    onSuccessListener: (formdata: FirebaseFormData[]) => void
): Unsubscribe => {

    let q;
    if(user.role === 'ADMIN') {
        q = query(
            collection(firestore, FORM_COLLECTION_NAME),
            orderBy("author.name", "asc")
        );
    }else {
        q = query(
            collection(firestore, FORM_COLLECTION_NAME),
            where("userId", "==", user.documentId),
            orderBy("author.name", "asc")
        );
    }



    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const forms: FirebaseFormData[] = [];
        querySnapshot.forEach((doc) => {
            const projectData: FirebaseFormData = {id: doc.id, ...doc.data()} as FirebaseFormData
            forms.push(projectData)
        });

        forms.sort(SortArray2)

        onSuccessListener(forms)
    }, (error) => {
        console.error(error)
        throw new Error('There was a problem getting the list.')
    });

    return unsubscribe;
}

export const objectToMap = (obj: any) => {
    const keys = Object.keys(obj)
    const map = new Map()
    for(let i = 0; i < keys.length; i++){
        map.set(keys[i], obj[keys[i]])
    }
    return map
};



function SortArray2(x: FirebaseFormData, y: FirebaseFormData){
    return x.author.name.localeCompare(y.author.name, 'fr', {ignorePunctuation: true});
}
