import React, {useEffect, useState} from "react";
import {checkExistForm, createForm} from "../../repositories/FormRepository";
import {AddIcon} from "../../components/svg/AddIcon";
import {DeleteIcon} from "../../components/svg/DeleteIcon";
import bannerImg from "../../assets/image/banner.jpg";
import {useForm} from "react-hook-form";
import {notificationAlert} from "../../util/alert";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import {Author} from "../../model/Author";
import {useNavigate} from "react-router-dom";
import {getUser} from "../../repositories/AuthRepository";


const FormPage: React.FC = () => {
    const [formCount, setFormCount] = useState<number>(1);
    const [refCount, setRefCount] = useState<number>(1);
    const {register, handleSubmit, watch, formState: {errors}, reset, setValue} = useForm<any>();
    const navigator = useNavigate();

    useEffect(() => {
        checkExist();
    }, [])

    const checkExist = async () => {
        const user = await getUser();
        if(user.role !== 'ADMIN') {
            const check = await checkExistForm();
            if(check) {
                notificationAlert('notification', 'You have already submitted a paper');
                navigator('/list')
            }
        }
    }

    const onSubmit = async (data: any) => {
        try {
            data.references = data.references.filter((ref: any) => ref !== '');
            let formData = {...data};

            if (formData.coauthors) {
                formData.coauthors = formData.coauthors.filter((coa: Author) => {
                    if (coa.email || coa.name || coa.affiliation || coa.country) {
                        return coa;
                    }
                    return;
                });
            }

            if (formData.references){
                formData.references = formData.references.filter((fi:string) => {
                    if (fi !== ""){
                        return fi;
                    }
                })
            }

            const result = await createForm(formData);
            if (result) {
                notificationAlert('notification', 'Success');
                reset();
                navigator('/list')
            }
        } catch (err) {
            notificationAlert('notification', 'server error');
        }
    }

    return (
        <div className={'form_page'}>
            <MathJaxContext config={{
                tex2jax: {
                    inlineMath: [['$', '$'], ['\\(', '\\)']],
                    displayMath: [['$', '$'], ['\\(', '\\)']],
                },
            }} version={2} hideUntilTypeset={'every'}>
                <div className={'container'}>
                    <div className={'banner'}>
                        <img src={bannerImg} alt={'banner_img'}/>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={'form_box_row'}>
                            <div className={'title flex_row_center'}>
                                <h5>Section <span>*</span></h5>
                            </div>
                            <div className={'content'}>
                                <label>
                                    <input {...register('section', {required: true})} type={'radio'}
                                           value={'Plenary Lecture'}/>
                                    Plenary Lecture
                                </label>
                                <label>
                                    <input {...register('section', {required: true})} type={'radio'}
                                           value={'Contributed Talk'}/>
                                    Contributed Talk
                                </label>
                            </div>
                            {errors.section && <span className={'validation_msg'}>This field is required</span>}
                        </div>
                        <div className={'form_box_row'}>
                            <div className={'title flex_row_center'}>
                                <h5>Session <span>*</span></h5>
                            </div>
                            <div className={'content'}>
                                <label>
                                    <input {...register('session', {required: true})} type={'radio'} value={'Ring'}/>
                                    Ring
                                </label>
                                <label>
                                    <input {...register('session', {required: true})} type={'radio'} value={'Module'}/>
                                    Module
                                </label>
                                <label>
                                    <input {...register('session', {required: true})} type={'radio'}
                                           value={'Representation'}/>
                                    Representation
                                </label>
                            </div>
                            {errors.session && <span className={'validation_msg'}>This field is required</span>}
                        </div>
                        <div className={'form_box_row'}>
                            <div className={'title flex_row_center'}>
                                <h5>Title(Eng.) <span>*</span></h5>
                            </div>
                            <div className={'content'}>
                                <p>*Please type numerical formula in title using
                                    LaTeX. <span>Do NOT use all capital.</span>
                                </p>
                                <textarea {...register('title', {required: true})}/>
                                <div>
                                    <MathJax hideUntilTypeset={"every"} inline dynamic>
                                        {watch("title")}
                                    </MathJax>
                                </div>
                            </div>
                            {errors.title && <span className={'validation_msg'}>This field is required</span>}
                        </div>
                        <div className={'form_box_column'}>
                            <div className={'form_title'}>
                                <div className={'title flex_row_center'}>
                                    <h5>
                                        Name of presenting author
                                        <span>*</span>
                                    </h5>
                                </div>
                                <div className={'title flex_row_center'}>
                                    <h5>
                                        Affiliation
                                    </h5>
                                </div>
                                <div className={'title flex_row_center'}>
                                    <h5>
                                        E-mail
                                    </h5>
                                </div>
                                <div className={'title flex_row_center'}>
                                    <h5>
                                        Country
                                    </h5>
                                </div>
                            </div>
                            <div className={'form_content'}>
                                <div className={'form_item'}>

                                    <div className={'content'}>
                                        <input {...register('author.name', {required: true})} type={'text'}/>
                                        {errors.author &&
                                            <span className={'validation_msg'}>This field is required</span>}
                                    </div>
                                </div>
                                <div className={'form_item'}>

                                    <div className={'content'}>
                                        <input {...register('author.affiliation')} type={'text'}/>
                                    </div>
                                </div>
                                <div className={'form_item'}>
                                    <div className={'content'}>
                                        <input {...register('author.email')} type={'text'}/>
                                    </div>
                                </div>
                                <div className={'form_item'}>
                                    <div className={'content'}>
                                        <input {...register('author.country')} type={'text'}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'form_box_column'}>
                            <div className={'form_title'}>
                                <div className={'title flex_row_center'}>
                                    <h5>
                                        Coauthor(s)
                                    </h5>
                                </div>
                                <div className={'title flex_row_center'}>
                                    <h5>
                                        Affiliation
                                    </h5>
                                </div>
                                <div className={'title flex_row_center'}>
                                    <h5>
                                        E-mail
                                    </h5>
                                </div>
                                <div className={'title flex_row_center'}>
                                    <h5>
                                        Country
                                    </h5>
                                </div>
                            </div>
                            {Array.from(Array(formCount).keys()).map(num => <div className={'form_content'}>
                                <div className={'form_item'}>

                                    <div className={'content'}>
                                        <input {...register(`coauthors[${num}].name`)} type={'text'}/>
                                    </div>
                                </div>
                                <div className={'form_item'}>

                                    <div className={'content'}>
                                        <input {...register(`coauthors[${num}].affiliation`)} type={'text'}/>
                                    </div>
                                </div>
                                <div className={'form_item'}>
                                    <div className={'content'}>
                                        <input {...register(`coauthors[${num}].email`)} type={'text'}/>
                                    </div>
                                </div>
                                <div className={'form_item'}>
                                    <div className={'content'}>
                                        <input {...register(`coauthors[${num}].country`)} type={'text'}/>
                                    </div>
                                </div>
                            </div>)}

                        </div>
                        <div className={'add_form'}>
                            <button type={'button'} onClick={() => setFormCount(old => old + 1)}>
                                <AddIcon/>
                            </button>
                            <button type={'button'} onClick={() => {
                                if (formCount > 1) {
                                    setFormCount(old => old - 1);
                                    setValue(`coauthors[${formCount - 1}].name`, '');
                                    setValue(`coauthors[${formCount - 1}].affiliation`, '');
                                    setValue(`coauthors[${formCount - 1}].email`, '');
                                    setValue(`coauthors[${formCount - 1}].country`, '');
                                }
                            }}>
                                <DeleteIcon/>
                            </button>
                        </div>

                        <div className={'form_box_row'}>
                            <div className={'title flex_row_center'}>
                                <h5>Abstract <span>*</span></h5>
                            </div>
                            <div className={'content'}>
                                <textarea {...register('abstractMath', {required: true})} name={'abstractMath'}/>
                                <div>
                                    <MathJax hideUntilTypeset={"every"} inline dynamic>
                                        {watch("abstractMath")}
                                    </MathJax>
                                </div>
                            </div>
                            {errors.abstractMath && <span className={'validation_msg'}>This field is required</span>}
                        </div>
                        <div className={'form_box_row'}>
                            <div className={'title flex_row_center'}>
                                <h5>References <span>*</span></h5>
                            </div>
                            <div className={'content'}>
                                <div className={'ref_form'}>
                                    {Array.from(Array(refCount).keys()).map(num =>
                                        <label>
                                            {`${num + 1}.`}
                                            <input {...register(`references[${num}]`)}
                                                   type={'text'}/>
                                        </label>
                                    )
                                    }
                                    <div className={'add_form'}>
                                        <button type={'button'} onClick={() => setRefCount(old => old + 1)}>
                                            <AddIcon/>
                                        </button>
                                        <button type={'button'} onClick={() => {
                                            if (refCount > 1) {
                                                setRefCount(old => old - 1);
                                                setValue(`references[${refCount - 1}]`, '');
                                                setValue(`references[${refCount - 1}]`, '');
                                                setValue(`references[${refCount - 1}]`, '');
                                                setValue(`references[${refCount - 1}]`, '');
                                            }
                                        }}>
                                            <DeleteIcon/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'form_box_row'}>
                            <div className={'title flex_row_center'}>
                                <h5>MSC number(s) <span>*</span>  <p style={{marginLeft: '1rem'}}>(ex. 53A25)</p></h5>
                            </div>
                            <div className={'content'}>
                                <input {...register('msc', {required: true})} className={'msc_input'} type={'text'}
                                       name={'msc'}/>
                            </div>
                            {errors.msc && <span className={'validation_msg'}>This field is required</span>}
                        </div>
                        <div className={'form_box_row'}>
                            <div className={'title flex_row_center'}>
                                <h5>Keyword(s) <span>*</span></h5>
                            </div>
                            <div className={'content'}>
                                <textarea {...register('keywords', {required: true})} />
                            </div>
                            {errors.keywords && <span className={'validation_msg'}>This field is required</span>}
                        </div>
                        <div className={'terms'}>
                            <p>Notice</p>
                            <p>(1) We may provide your e-mail address and phone number you submitted to the conference
                                program
                                committee form the purpose of preparing for the Conference Meeting.</p>
                            <p>(2) Please note that if you cancel the presentation or do not meet the designated time
                                without
                                further contact, your presentation may be limited.</p>
                            <div>
                                <label>
                                    <input {...register('terms_agree')} type={'radio'} value={'agree'}/>
                                    I agree
                                </label>
                                <label>
                                    <input {...register('terms_agree')} type={'radio'} value={'refuse'}/>
                                    I don't agree
                                </label>
                            </div>
                        </div>
                        <button
                            disabled={Object.keys(errors).length !== 0}
                            className={watch('terms_agree') === 'agree' ? 'active' : ''}
                            id={'submit_btn'} type={'submit'}>Submit
                        </button>
                    </form>
                </div>
            </MathJaxContext>
        </div>
    )
}


export default FormPage;
