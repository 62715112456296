import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {notificationAlert} from "../../util/alert";
import {deleteForm, getDetailForm} from "../../repositories/FormRepository";
import {FirebaseFormData} from "../../model/FirebaseFormData";
import {Timestamp} from "firebase/firestore";
import Swal from "sweetalert2";
import {MathJax, MathJaxContext} from "better-react-mathjax";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {isIterableArray} from "../../util/common";


const FormDetailPage: React.FC = () => {
    const navigator = useNavigate();
    const {id} = useParams<{ id: string }>();
    const pdfRef = useRef<HTMLDivElement>(null);
    const [formDetail, setFormDetail] = useState<FirebaseFormData>({
        id: "",
        userId: "",
        section: "",
        session: "",
        title: "",
        author: {
            name: "",
            affiliation: "",
            email: "",
            country: "",
        },
        coauthors: [],
        abstractMath: "",
        references: [],
        msc: "",
        keywords: "",
        createAt: Timestamp.now()
    });


    const handlePrint = async () => {
        const paper = document.querySelector(".preview_paper");
        if (!paper) return;
        const C = document.getElementsByTagName("mjx-assistive-mml");
        for (let i = 0; i < C.length; i++) {
            const testHTML = C[i] as HTMLElement;
            testHTML.style.opacity = "0.0";
        }

        const canvas = await html2canvas(paper as HTMLElement, {scale: 2});
        const imageFile = canvas.toDataURL("image/png", 1.0);

        const doc = new jsPDF("p", "mm", "a4");

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        doc.addImage(imageFile, "JPEG", 0, 0, pageWidth, pageHeight);


        window.open(doc.output("bloburl"))

    }


    useEffect(() => {
        if (id) {
            handleGetDetailForm(id);
        }
    }, [id]);

    const handleGetDetailForm = async (formId: string) => {
        try {
            const result = await getDetailForm(formId);
            if (result) {
                setFormDetail(result);
            }

        } catch (err: any) {
            notificationAlert('notification', err?.message || 'server error');
            setTimeout(() => {
                navigator('/list');
            }, 1000)

        }
    }

    const handleDelForm = async () => {
        try {
            if (!id) return;
            Swal.fire({
                title: 'Are you sure you want to delete this form?',
                confirmButtonText: 'delete',
                showCancelButton: true,
                cancelButtonText: 'cancel'
            }).then(async result => {
                if (result.isConfirmed) {
                    const result = await deleteForm(id);
                    if (result) {
                        notificationAlert('notification', 'success delete form');
                        navigator(-1);
                    }
                }
            })


        } catch (err) {
            notificationAlert('notification', 'server error');
        }
    }


    return (
        <div className={'form_detail_page'}>
            <MathJaxContext
                onStartup={(mathJax) => (mathJax.Hub.processSectionDelay = 0)}
                config={{
                    tex2jax: {

                        inlineMath: [['$', '$'], ['\\(', '\\)']],
                        displayMath: [['$', '$'], ['\\(', '\\)']],
                    },
                    CommonHTML: {
                        linebreaks: {automatic: true},
                        mtextFontInherit: true
                    }
                }}
                version={2}>
                <div className={'container'}>
                    <div className={'fc_wrap'}>
                        <button onClick={() => navigator(-1)}>back</button>
                        <div className={'flex_row_center'}>
                            <button onClick={handlePrint}>Download PDF</button>
                            <button onClick={() => navigator(`/form/${id}/modify`)}>Edit</button>
                            <button onClick={handleDelForm}>Delete</button>
                        </div>
                    </div>
                    <div className={'page_title'}>
                        <h2>Preview</h2>
                    </div>
                    <div ref={pdfRef} className={'preview_paper page'}>
                        <div className={'title'}>
                            <p>
                                <MathJax hideUntilTypeset={"every"} inline dynamic>
                                    {formDetail.title}
                                </MathJax>
                            </p>
                        </div>
                        <div className={'name'}>
                            <p>
                                {formDetail.author.name && formDetail.coauthors.length === 1 ? `${formDetail.author.name}* and ` : `${formDetail.author.name}* `}
                                {isIterableArray(formDetail.coauthors) && formDetail.coauthors.length === 1 ? formDetail.coauthors[0].name : formDetail.coauthors.map((auth, index) => (index === (formDetail.coauthors.length - 1)) ? ` and ${auth.name}` || '' : auth.name && `${formDetail.author.name ? 'and' : ''} ${auth.name}`)}

                            </p>
                        </div>
                        <div className={'affiliation'}>
                            <p>
                                {formDetail.author.affiliation && formDetail.coauthors.length === 1 ? `${formDetail.author.affiliation} and ` : `${formDetail.author.affiliation} `}
                                {isIterableArray(formDetail.coauthors) && formDetail.coauthors.length === 1 ? formDetail.coauthors[0].affiliation : formDetail.coauthors.map((auth, index) => (index === (formDetail.coauthors.length - 1)) ? ` and ${auth.affiliation}` || '' : auth.affiliation && `${formDetail.author.affiliation ? 'and' : ''} ${auth.affiliation}`)}
                            </p>
                        </div>
                        <div className={'email'}>
                            <p>
                                <span>Email(s): </span>
                                {formDetail.author.email && formDetail.coauthors.length === 1 ? `${formDetail.author.email} , ` : `${formDetail.author.email} `}
                                {isIterableArray(formDetail.coauthors) && formDetail.coauthors.length === 1 ? formDetail.coauthors[0].email : formDetail.coauthors.map((auth, index) => (index === (formDetail.coauthors.length - 1)) ? ` , ${auth.email}` || '' : auth.email && `${formDetail.author.email ? ',' : ''} ${auth.email}`)}

                            </p>
                        </div>
                        <div className={'abstract'}>
                            <p>
                                <MathJax  hideUntilTypeset={"every"} inline dynamic>
                                    {formDetail.abstractMath}
                                </MathJax>
                            </p>
                        </div>
                        <div className={'references'}>
                            <p className={'references_title'}>R
                                <span>
                            EFERENCES
                                </span>
                            </p>
                            <p className={'references_content'}>
                                <ol>
                                    {
                                        formDetail.references && formDetail.references.map((ref) =>
                                            <li>{ref}</li>)
                                    }
                                </ol>
                            </p>
                        </div>
                        <div className={'bottom_msg'}>
                            <hr/>
                            <div style={{textAlign: 'left'}}>
                                <p>
                                    2020 Mathematics Subject Classification: {formDetail.msc}
                                </p>
                            </div>
                            <div className={'keywords'}>
                                <p style={{textAlign: 'left'}}>
                                    Keywords: {formDetail.keywords}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </MathJaxContext>
        </div>
    )
}


export default FormDetailPage;
