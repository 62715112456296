import {registerState} from './atoms'

import { useRecoilState } from 'recoil'
import React from 'react'
import {InputBase} from "../common";

type Props = {
    type: string
    name: string
    placeholder: string
}

const Input: React.FC<Props> = ({ type, name, placeholder }: Props) => {
    const [state, setState] = useRecoilState(registerState)

    return (
        <InputBase
            className={'auth_input'}
            type={type}
            name={name}
            placeholder={placeholder}
            state={state}
            setState={setState}
        />
    )
}

export default Input
