import React, {useEffect, useState} from "react";
import {getFormList} from "../../repositories/FormRepository";
import {FirebaseFormData} from "../../model/FirebaseFormData";
import dayjs from 'dayjs';
import {useNavigate} from "react-router-dom";
import {getUser} from "../../repositories/AuthRepository";

const FormListPage: React.FC = () => {

    const [formList, setFormList] = useState<FirebaseFormData[]>([]);

    useEffect(() => {
        getFormData();
    }, [])

    const getFormData = async () => {
        const user = await getUser();

        const unsubscribe = getFormList(user, formData => {

            console.log('formData', formData)
            if (formData) {
                setFormList(formData);
            }
        })
        return () => unsubscribe();
    }

    const navigator = useNavigate();

    const logout = () => {
        localStorage.removeItem('fmEE')
        navigator('/login')
    }

    return (
        <div className={'form_list_page container'}>
            <div className={'page_title'}>
                <h2>List of speakers</h2>
                <div className={'page_functions'}>
                    <button onClick={() => navigator('/create')}>Write Form</button>
                    <button onClick={logout}>Logout</button>
                </div>
            </div>
            <div>
                <table className={'form_list_table'}>
                    <colgroup>
                        <col width={'10%'}/>
                        <col width={'15%'}/>
                        <col width={'60%'}/>
                        <col width={'15%'}/>
                    </colgroup>
                    <thead>
                    <tr>
                        <th>No</th>
                        <th>authorName</th>
                        <th align={'left'}>title</th>
                        <th>created</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        formList.map((form, index) => <tr onClick={() => navigator(`/form/${form.id}/detail`)}>
                            <td align={'center'}>{index + 1}</td>
                            <td align={'center'}>{form.author.name}</td>
                            <td align={'left'}>{form.title}</td>
                            <td align={'center'}>{form.createAt && dayjs(form.createAt.toDate()).format('YYYY MM-DD')}</td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}


export default FormListPage;
