import {atom} from 'recoil'


interface IState {
    email: string
    name: string
    id: string
    password: string
    role: string
    privacyAg: boolean,
}

export const registerState = atom<IState>({
    key: 'registerState',
    default: {
        email: '',
        name: '',
        id: '',
        password: '',
        role: '',
        privacyAg: false,
    }
})
