import {BrowserRouter, Routes, Route} from 'react-router-dom';
import React from 'react';
import FormPage from "./pages/form/FormPage";
import {RecoilRoot} from 'recoil'
import {RouteIf} from "./util/RouteIf";
import LoginMain from "./pages/login/LoginMain";
import FormListPage from "./pages/list/FormListPage";
import FormDetailPage from "./pages/detail/FormDetailPage";
import FormModifyPage from "./pages/modify/FormModifyPage";
import {MathJaxContext} from "better-react-mathjax";
import Register from "./pages/register/Register";


const Router: React.FC = () => (
    <RecoilRoot>
        <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={
                        <RouteIf option={true}>
                            <FormPage />
                        </RouteIf>
                    }/>
                    <Route path={"/login"} element={
                        <RouteIf optionFree={false} option={false}>
                            <LoginMain/>
                        </RouteIf>
                    }/>
                    <Route path={"/register"} element={
                        <RouteIf optionFree={false} option={false}>
                            <Register />
                        </RouteIf>
                    } />
                    <Route path={"/create"} element={
                        <RouteIf option={true}>
                            <FormPage />
                        </RouteIf>
                    }/>
                    <Route path={"/list"} element={
                        <RouteIf option={true}>
                            <FormListPage/>
                        </RouteIf>
                    }/>
                    <Route path={"/form/:id/detail"} element={
                        <RouteIf option={true}>
                            <FormDetailPage/>
                        </RouteIf>
                    }/>
                    <Route path={"/form/:id/modify"} element={
                        <RouteIf option={true}>
                            <FormModifyPage/>
                        </RouteIf>
                    }/>
                </Routes>
        </BrowserRouter>
    </RecoilRoot>
);

export default Router;
