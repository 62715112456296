import {atom} from 'recoil'


interface IState {
    id: string,
    password: string
}

export const loginState = atom<IState>({
    key: 'loginState',
    default: {
        id: '',
        password: ''
    }
})
