// import {initializeApp} from "firebase/app"
// import {getFirestore} from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import initializeApp = firebase.initializeApp;
import { getFirestore } from 'firebase/firestore';

// Import the functions you need from the SDKs you need

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAUiAQ0RN2TRafJ4EeRvT3nXnXQAN1TfWE",
    authDomain: "form-kang.firebaseapp.com",
    projectId: "form-kang",
    storageBucket: "form-kang.appspot.com",
    messagingSenderId: "367702485172",
    appId: "1:367702485172:web:447c204c942bfd8cd48fe1"
};


const app = initializeApp(firebaseConfig);
// const functions = getFunctions(app, 'asia-northeast3');
const firestore = getFirestore(app);

export {firestore};
