import React, {useEffect} from "react"
import {Checkbox, FormControlLabel} from "@mui/material";
import {useRecoilState, useResetRecoilState} from "recoil";
import {registerState} from "./atoms";
import {notificationAlert} from "../../util/alert";
import {useNavigate} from "react-router-dom";
import {loaderState} from "../common/loader/atoms";
import Loader from "../common/loader/Loader";
import Input from "./Input";
import {register} from "../../repositories/AuthRepository";


const Register: React.FC = () => {

    const [state, setState] = useRecoilState(registerState)
    const [loader, setLoader] = useRecoilState(loaderState)
    const navigator = useNavigate()
    const resetState = useResetRecoilState(registerState)
    useEffect(() => {
        resetState()
    }, [])


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault()
        try {

            setLoader(old => ({...old, register: true}))

            const result = await register({
                email: state.email,
                name: state.name,
                id: state.id,
                password: state.password,
                role: 'NORMAL'
            })

            if (result === 'ok') {
                notificationAlert('알림', '회원가입이 완료되었습니다.')
                setLoader(old => ({...old, register: false}))
                setTimeout(() => {
                    navigator('/login')
                }, 1000)
            } else {
                setLoader(old => ({...old, register: false}))
                notificationAlert('알림', result)
            }

        } catch (err: any) {
            if (err) {
                setLoader(old => ({...old, register: false}))
                if (err.message) {
                    notificationAlert('오류', err.message)
                } else {
                    notificationAlert('오류', '회원가입에 문제가 발생하였습니다.')
                }
            }
        }

    }

    const handleAgreePolicy = (checked: boolean, type: string) => {
        if (type === 'useP') {
            setState(old => ({...old, usePolicyAg: checked}))
        } else if (type === 'prvP') {
            setState(old => ({...old, privacyAg: checked}))
        } else {
            setState(old => ({...old, locationAg: checked}))
        }
    }

    return (
        <div className={'login_main'}>
            <div className='login_box'>
                <h3 id={'box_title'}>Register</h3>
                {loader.getAccountInfo ? <Loader height={500}/> :
                    <form onSubmit={handleSubmit}>
                        <div style={{padding: '1rem 0'}}>
                            <Input type={'text'} name={'id'} placeholder={'ID'}/>
                        </div>
                        <div style={{padding: '1rem 0'}}>
                            <Input type={'password'} name={'password'} placeholder={'Password'}/>
                        </div>
                        <div style={{padding: '1rem 0'}}>
                            <Input type={'text'} name={'email'} placeholder={'email'}/>
                        </div>
                        <div style={{padding: '1rem 0'}}>
                            <Input type={'text'} name={'name'} placeholder={'name'}/>
                        </div>

                        <div className={'register_policy_main'}>
                            <FormControlLabel className='support_agree_policy_form' control={<Checkbox
                                onChange={event => handleAgreePolicy(event.target.checked, 'prvP')}/>}
                                              label={'Terms and Conditions (Required)'}/>
                        </div>
                        <div className={'form_btn_wrapper'}>
                            <button
                                disabled={(!state.privacyAg)}
                                type={"submit"} id={'login_submit_btn'}>
                                {loader.register ? <Loader height={25}/> : 'Register'}
                            </button>
                        </div>
                    </form>
                }

            </div>
        </div>
    )
}


export default Register
