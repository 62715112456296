import {addDoc, collection, deleteDoc, doc, onSnapshot, where, getDocs, query, Unsubscribe, updateDoc, Timestamp, getDoc, orderBy } from "firebase/firestore";
import {firestore} from "../firebase";
import {FirebaseUserData} from "../model/FirebaseUserData";
import bcrypt from 'bcryptjs';
import {isIterableArray} from "../util/common";


const USER_COLLECTION_NAME = "users"


export const register = async (
    request: FirebaseUserData
): Promise<string> => {
    try {
        const userQuery = query(collection(firestore, USER_COLLECTION_NAME), where('id', '==', request.id));
        const userSnap = await getDocs(userQuery);
        if(!userSnap.empty) {
            return '이미 존재하는 아이디입니다.';
        }

        request.createAt = Timestamp.now()
        request.password = await bcrypt.hash(request.password, 10);
        await addDoc(collection(firestore, USER_COLLECTION_NAME), request);
        return 'ok';
    }catch (e) {
        console.error(e);
        return '서버에 문제가 발생했습니다.';
    }

}

export const login = async (
    id: string,
    pwd: string
): Promise<string> => {

    try {
        const userQuery = query(collection(firestore, USER_COLLECTION_NAME), where('id', '==', id));
        const userSnap = await getDocs(userQuery);

        if (!isIterableArray(userSnap.docs)) {
            return 'Please check your ID or password.';
        }

        const userDoc = userSnap.docs[0]
        const user: FirebaseUserData = {
            documentId: userDoc.id,
            ...userDoc.data()
        } as FirebaseUserData

        const compare = await bcrypt.compare(pwd, user.password);
        if(compare) {
            localStorage.setItem('fmEE', userDoc.id)
            return 'ok'
        }else {
            return 'Please check your ID or password.';
        }

    }catch (e) {
        return 'server error';
    }

}

export const getUser = async (): Promise<FirebaseUserData> => {
    const myId = localStorage.getItem('fmEE') as string
    const userDoc = await getDoc(doc(firestore, USER_COLLECTION_NAME, myId));
    if (!userDoc) {
        throw new Error('Your session has expired.')
    }

    const user: FirebaseUserData = {
        documentId: userDoc.id,
        ...userDoc.data()
    } as FirebaseUserData

    return user;
}
